import React, { useEffect, useRef, useState } from 'react';

export default function ErfCarteWoosMaps({
  position,
  markers,
  selectedMarker,
  setSelectedMarker,
  zoom,
  onMapChange, // Add a new prop to handle the event
}) {
  const theMap = useRef(null);
  const markerRefs = useRef([]);

  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const currentZoom = useRef(4);

  useEffect(() => {
    const initWoosmap = () => {
      const map = new woosmap.map.Map(document.getElementById('map'), {
        zoom: 4,
        center: position,
        disableTilt: true,
        styles: [
          
          {
            "featureType": "point_of_interest.education",
            "elementType": "all",
            "stylers": [
              {
                "visibility": "on"
              }
            ]
          },
          {
            "featureType": "point_of_interest.government",
            "elementType": "all",
            "stylers": [
              {
                "visibility": "on"
              }
            ]
          },
          
          {
            "featureType": "point_of_interest.medical",
            "elementType": "all",
            "stylers": [
              {
                "visibility": "on"
              }
            ]
          },
          {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
              {
                "visibility": "on"
              }
            ]
          }
        ]
      });

      theMap.current = map;

      // Add an idle event listener to detect when the map has finished panning or zooming
      map.addListener('idle', () => {
        const center = map.getCenter();
        const zoom = map.getZoom();
        const bounds = map.getBounds();
        onMapChange && onMapChange({
          lat: center.lat(),
          lng: center.lng(),
          zoom: zoom,
          center: center,
          bounds: map.getBounds(),
        });
      });

      console.log('Woosmap loaded');
      map.panTo(position);
      map.setZoom(4);

      onMapChange && onMapChange({
        lat: position.lat,
        lng: position.lng,
        zoom: 4,
        center: position,
        bounds: map.getBounds(),
      });
    };
    const checkWoosmap = () => {
      console.log('checking woosmap');
      const isElementOnPage = !!document.getElementById('map');
      if (window.woosmap && window.woosmap.map && isElementOnPage) {
        initWoosmap();
      } else {
        setTimeout(() => {
          checkWoosmap();
        }, 100);
      }
    };
    checkWoosmap();
  }, []);

  useEffect(() => {
    if (!theMap.current) return;

    // Remove existing markers
    markerRefs.current.forEach(marker => marker.setMap(null));
    markerRefs.current = [];

    if (markers.length === 0) return;

    markers.forEach((marker, index) => {
      const longitude = marker.Longitude;
      const latitude = marker.Latitude;

      const theMarker = new woosmap.map.Marker({
        position: { lat: latitude, lng: longitude },
        icon: {
          url: '/assets/images/pin-orange.png',
          scaledSize: {
            height: 40,
            width: 40,
          },
        },
      });

      theMarker.addListener('click', () => {
        setSelectedMarker(marker);

        if (currentZoom.current < 10) {
          currentZoom.current = 12;
          theMap.current.setZoom(12);
        }
        theMap.current.panTo({ lat: latitude, lng: longitude });
      });

      theMarker.setMap(theMap.current);
      markerRefs.current.push(theMarker);
    });

    console.log("Created " + markers.length + " markers");

  }, [markers, setSelectedMarker]);

  useEffect(() => {
    if (theMap.current && position) {
      theMap.current.panTo(position);
    }
  }, [position]);

  useEffect(() => {
    if (theMap.current && zoom) {
      theMap.current.setZoom(zoom);
      theMap.current.panTo(position);
    }
  }, [zoom]);

  return (
    <>
      <div id="map" style={{ width: '100%', height: '100%' }}></div>
    </>
  );
}
