

document.addEventListener('DOMContentLoaded', () => {
  function splitTextIntoLines(selector) {
      const elements = document.querySelectorAll(selector);
      if (!elements) {
          return;
      }

      elements.forEach(element => {


        const originalText = element.innerText;
  
        function splitText() {
            const text = element.innerText;
            const words = text.split(/\s+/);
  
            element.innerHTML = '';
  
            // Replace each word with a span
            words.forEach((word, index) => {
                const span = document.createElement('span');
                span.textContent = word;
                element.appendChild(span);
                if (index < words.length - 1) {
                    const space = document.createElement('span');
                    space.textContent = ' ';
                    element.appendChild(space);
                }
            });
  
            const spans = Array.from(element.querySelectorAll('span'));
            const lines = [];
            let currentLine = [];
            let lastSpan = spans[0];
  
            spans.forEach((span, index) => {
                const rect = span.getBoundingClientRect();
                const lastRect = lastSpan.getBoundingClientRect();
  
                // Check if span is on a new line
                if (rect.top > lastRect.top) {
                    lines.push(currentLine);
                    currentLine = [];
                }
  
                currentLine.push(span);
                lastSpan = span;
            });
  
            if (currentLine.length) {
                lines.push(currentLine);
            }
  
            // Clear the original content
            element.innerHTML = '';
  
            // Group spans into lines and create new span elements for each line
            lines.forEach((line, index) => {
                const lineSpan = document.createElement('span');
                lineSpan.style.display = 'inline-block';
                lineSpan.style.setProperty('--delay', 1 + 0.3 * index + 's');
                lineSpan.textContent = line.map(span => span.textContent).join('');
                element.appendChild(lineSpan);
            });
            
        }
  
        // Initial split
        splitText();
  
        // Add resize event listener
        window.addEventListener('resize', () => {
            element.innerText = originalText; // Reset to original text
            splitText(); // Resplit text
        });

        
      });

  }

  splitTextIntoLines('h2 strong');

  // Intersection Observer to trigger animation once
  function observeElements(selector) {
      const elements = document.querySelectorAll(selector);

      elements.forEach(element => {

        if (!element) {
            console.error('Element not found');
            return;
        }

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Add animation class or trigger animation
                    entry.target.classList.add('animate');
                    // Stop observing after the animation is triggered once
                    observer.unobserve(entry.target);
                }
            });
        }, { threshold: 0.1 }); // Adjust threshold as needed

        observer.observe(element);

      });
    }
    observeElements('h2 strong');

});

