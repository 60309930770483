import React, { useEffect } from "react";
import { FiExternalLink as ExternalLinkIcon } from "react-icons/fi";
import { AiOutlineInfoCircle as InformationCircleIcon } from "react-icons/ai";
import {
  FaArrowRight,
  FaCheckCircle,
  FaTimesCircle,
  FaInfoCircle,
  FaMapMarkerAlt,
} from "react-icons/fa";
import ErfGoogleRating from "./ErfGoogleRating";

const ErfLaboSnippet = ({ labo, strings, lang, width, small = false }) => {
  const [displayedHoraire, setDisplayedHoraire] = React.useState("");
  const currentLang = lang;

  useEffect(() => {
    if (!labo.HorairesExceptionnels) {
      const usDay = new Date().toLocaleDateString("en-US", { weekday: "long" });
      const currentDay =
        currentLang == "fr"
          ? new Date().toLocaleDateString("fr-FR", { weekday: "long" })
          : usDay;
      const frToEnDays = {
        Lundi: "Monday",
        Mardi: "Tuesday",
        Mercredi: "Wednesday",
        Jeudi: "Thursday",
        Vendredi: "Friday",
        Samedi: "Saturday",
        Dimanche: "Sunday",
      };

      const enFerme = "Closed";

      // Monday is 0, Sunday is 6
      const dayIndex =
        usDay == "Monday"
          ? 0
          : usDay == "Tuesday"
          ? 1
          : usDay == "Wednesday"
          ? 2
          : usDay == "Thursday"
          ? 3
          : usDay == "Friday"
          ? 4
          : usDay == "Saturday"
          ? 5
          : usDay == "Sunday"
          ? 6
          : -1;

      const horaire = labo.Horaires[dayIndex].Horaire; //[currentDay];

      if (labo.HorairesExceptionnels) {
        const horairesX = labo.Horaires[0].Horaire;
        const horairesXDisplay =
          horairesX == "Fermé" && currentLang == "en" ? enFerme : horairesX;
        setDisplayedHoraire(`(${horairesXDisplay})`);
      } else {
        setDisplayedHoraire(horaire);
      }
    }
  }, [labo]);

  const getGMapsDirectionsUrl = (labo) => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${labo.Latitude},${labo.Longitude}&travelmode=driving`;
    return url;
  };

  return (
    <div className={small ? "" : "p-4 py-2 w-full"}>
      <div
        className={small ? `` : `border border-gray-300 rounded-md p-4 w-full`}
      >
        <h3 className="mb-2 text-xl font-bold">
          {small ? (
            <span className="pb-1">
              <a
                href={
                  (lang == "fr" ? "/fr" : "/en") + labo.Url.replace("//", "/")
                }
                className=""
              >
                {labo.NomLabo}
              </a>
            </span>
          ) : (
            <a
              href={
                (lang == "fr" ? "/fr" : "/en") + labo.Url.replace("//", "/")
              }
            >
              {labo.NomLabo}
            </a>
          )}
        </h3>
        <div className="mb-2 flex items-center">
          {labo.IsOpen ? (
            <FaCheckCircle className="h-5 w-5 text-green-500" />
          ) : (
            <FaTimesCircle className="h-5 w-5 text-red-500" />
          )}
          <span className={`ml-2 text-[14px]`}>
            {labo.IsOpen ? (
              <span className="pr-2 font-bold text-green-500">
                {strings.ouvert[currentLang]}
              </span>
            ) : (
              <span className="pr-2 font-bold text-red-500">
                {strings.ferme[currentLang]}
              </span>
            )}
            {displayedHoraire}
          </span>
        </div>
        <p className={small || width <= 500 ? "mb-0" : "mb-4"}>
          {labo.Adresse}
          {/* {JSON.stringify(labo)} */}
        </p>

        {labo.NoteGoogle && labo.NoteGoogle > 0 && (
          <ErfGoogleRating
            total={labo.NombreAvis}
            rating={labo.NoteGoogle}
            googlePlaceId={labo.GooglePlaceId}
          />
        )}

        <div
          className={
            small
              ? `flex gap-2`
              : width <= 500
              ? `flex gap-4 justify-around`
              : `grid grid-cols-3 gap-2`
          }
        >
          <a
            href={(lang == "fr" ? "/fr" : "/en") + labo.Url.replace("//", "/")}
            className={` ${
              width <= 500 ? "w-[36px] text-center m-0 " : ""
            }cta mt-4 text-sm normal small`}
          >
            {!small && (
              <FaInfoCircle
                className={`"inline ${
                  small ? "" : width < 500 ? "-left-[5px] relative " : "mr-2"
                } text-[20px]"`}
                style={{
                  fontSize: "18px",
                  width: "30px",
                  maxHeight: "30px",
                  display: "inline",
                }}
                alt=""
              />
            )}
            {((width > 500 && !small) || small) &&
              strings.horaire_douverture_et_informations[currentLang]}
          </a>
          <a
            href={getGMapsDirectionsUrl(labo)}
            target="_blank"
            className={` ${
              width <= 500 ? "w-[36px] " : ""
            }cta secondary mt-4 text-sm normal small px-1 min-w-[36px] `}
          >
            <FaMapMarkerAlt
              className={`"inline ${
                small ? "" : "mr-2"
              } text-[20px] text-white"`}
              style={{ width: "18px", maxHeight: "22px", display: "inline" }}
              alt=""
            />
            {!small && width > 500 && strings.itineraires[currentLang]}
          </a>
          <a
            href={labo.LienRDV ? labo.LienRDV : labo.Url}
            className={` ${
              width <= 500 ? "w-[36px] " : ""
            }cta blue mt-4 text-sm normal small min-w-[36px]`}
            target="_blank"
          >
            <img
              src="/assets/images/rdv.svg"
              className={`"inline ${small ? "" : "mr-2"} text-[20px]"`}
              style={{ width: "18px", maxHeight: "22px", display: "inline" }}
              alt=""
            />
            {!small && width > 500 && strings.lienRDV[currentLang]}
          </a>
          {small && (
            <a
              href={strings.urlResultats[currentLang]}
              className={` ${
                width <= 500 ? "w-[36px] " : ""
              }cta lightblue mt-4 text-sm normal small min-w-[36px]`}
            >
              <svg
                id="Capa_1"
                enable-background="new 0 0 512 512"
                height="512"
                className={`"inline ${small ? "" : "mr-2"} text-[20px]"`}
                style={{ width: "18px", maxHeight: "22px", display: "inline" }}
                viewBox="0 0 512 512"
                width="512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path d="m337.232 0h-261.232v512h360v-412.794zm8.768 51.327 38.9 39.073h-38.9zm-240 430.673v-452h210v90.4h90v361.6z" />
                  <path d="m256 193.7c-59.276 0-107.5 48.224-107.5 107.5s48.224 107.5 107.5 107.5c59.275 0 107.5-48.224 107.5-107.5s-48.225-107.5-107.5-107.5zm0 185c-42.734 0-77.5-34.766-77.5-77.5s34.766-77.5 77.5-77.5c42.733 0 77.5 34.766 77.5 77.5s-34.767 77.5-77.5 77.5z" />
                  <path d="m271 256.2h-30v30h-30v30h30v30h30v-30h30v-30h-30z" />
                </g>
              </svg>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErfLaboSnippet;
