import React from 'react';
import { Field, useFormikContext } from 'formik';

const RadioGroup = ({ name, label, options, errors, touched, helpText, displayHelpText, onNext }) => {
  const { setFieldValue } = useFormikContext();

  const handleClick = (value) => {
    setFieldValue(name, value);
    onNext && onNext(); // Call the function to move to the next step
  };

  return (
    <div className='mb-4'>
      <label className='mb-2 block font-arial text-[20px]'>{label}</label>
      <div role="group" aria-labelledby={name} className='flex flex-col space-y-2'>
        {options.map(option => (
          <button
            key={option.value}
            type="button"
            onClick={() => handleClick(option.value)}
            className={`px-4 py-2 border-2 hover:bg-orange-500 hover:text-white transition-all border-orange-500 text-[18px] font-arial rounded-[1rem] w-[300px] ${
              touched[name] && errors[name] ? 'border-red-500' : ''
            }`}
          >
            {option.label}
          </button>
        ))}
      </div>
      {errors[name] && touched[name] ? <div className='text-arial mt-1 text-red-500'>{errors[name]}</div> : null}
      {displayHelpText && helpText && <div className='text-arial mt-2 border-l-4 border-orange-500 bg-orange-100 p-4 text-black'>{helpText}</div>}
    </div>
  );
};

export default RadioGroup;
